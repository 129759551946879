<template>
  <div>
      <vue-header title="舌诊" isReturn="true" />
      <face active="2"/>
      <div v-if="device!==3">
      <div :class="{ device: device==1?true:false }">
        <div class="p1">
          <div class="step">
            <div class="step-mint">
              <div class="mint-color">您即将进入<span>[舌诊]</span></div>
            </div>
            <div class="step-notice flex">
              <div class="flex-1 flex flex-y">
                <div class="step-line"></div>
              </div>
              <div class="notice-text">拍摄须知</div>
              <div class="flex-1 flex flex-y">
                <div class="step-line"></div>
              </div>
            </div>
            <div class="step-tips flex">
              <div class="tips flex-1 flex flex-x">
                <div>
                  <img src="../../../images/photo-alarm1.png"/>
                  <p>请将舌头尽量平展伸出</p>
                </div>
              </div>
              <div class="tips flex-1 flex flex-x">
                <div>
                  <img src="../../../images/photo-alarm2.png"/>
                  <p>请将舌头对准像框正中央</p>
                </div>
              </div>
            </div>
            <div class="tips-alert">
              温馨提示：苹果手机，系统版本在13.0时，向右旋转横屏拍照
              <img src="../../../images/rotate.png"/>
            </div>
            <button class="click-photo">点击屏幕拍照</button>
          </div>
          <img src="../../../images/tongue-test.png" class="face-demo"/>
        </div>
        <div class="p2">
          <div class="transverse flex flex-x">
              <img src="../../../images/face2.png"  />
          </div>
          <div class="mint-color trans-size">您即将进入<span>[舌诊]</span></div>
          <button class="face-photo trans-photo">点击屏幕拍照</button>
          <div class="step-notice flex">
              <div class="flex-1 flex flex-y">
                <div class="step-line"></div>
              </div>
              <div class="notice-text">拍摄须知</div>
              <div class="flex-1 flex flex-y">
                <div class="step-line"></div>
              </div>
          </div>
          <div class="step-tips flex">
            <div class="tips flex-1 flex flex-x">
              <div>
                <img src="../../../images/photo-alarm1.png"/>
                <p>请将舌头尽量平展伸出</p>
              </div>
            </div>
            <div class="tips flex-1 flex flex-x">
              <div>
                <img src="../../../images/photo-alarm2.png"/>
                <p>请将舌头对准像框正中央</p>
              </div>
            </div>
          </div>
          <div class="tips-alert">
              温馨提示：苹果手机，系统版本在13.0时，向右旋转横屏拍照
              <img src="../../../images/rotate.png"/>
          </div>
        </div>
        <div class="p3">
          <div class="face-device flex flex-x">
              <img src="../../../images/tongue-device.png"  />
          </div>
          <div class="mint-color">您即将进入<span>[舌诊]</span></div>
          <button class="face-photo" @click="photoFun">开始舌诊</button>
          <div class="step-notice flex">
              <div class="flex-1 flex flex-y">
                <div class="step-line"></div>
              </div>
              <div class="notice-text">拍摄须知</div>
              <div class="flex-1 flex flex-y">
                <div class="step-line"></div>
              </div>
          </div>
          <div class="step-tips flex">
            <div class="tips flex-1 flex flex-x">
              <div>
                <img src="../../../images/photo-alarm1.png"/>
                <p>请将舌头尽量平展伸出</p>
              </div>
            </div>
            <div class="tips flex-1 flex flex-x">
              <div>
                <img src="../../../images/photo-alarm2.png"/>
                <p>请将舌头对准像框正中央</p>
              </div>
            </div>
          </div>
        </div>
        <div class="p4">
          <div class="transverse flex flex-x">
              <img src="../../../images/face2.png"  />
          </div>
          <div class="mint-color trans-size">您即将进入<span>[舌诊]</span></div>
          <button class="face-photo trans-photo" @click="photoFun">开始舌诊</button>
          <div class="step-notice flex">
              <div class="flex-1 flex flex-y">
                <div class="step-line"></div>
              </div>
              <div class="notice-text">拍摄须知</div>
              <div class="flex-1 flex flex-y">
                <div class="step-line"></div>
              </div>
          </div>
          <div class="step-tips flex">
            <div class="tips flex-1 flex flex-x">
              <div>
                <img src="../../../images/photo-alarm1.png"/>
                <p>请将舌头尽量平展伸出</p>
              </div>
            </div>
            <div class="tips flex-1 flex flex-x">
              <div>
                <img src="../../../images/photo-alarm2.png"/>
                <p>请将舌头对准像框正中央</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <input-file type="2" v-if="device!==1" />
      </div>
  </div>
</template>
<script>
import vueHeader from '@/components/header';
import face from '@/components/face';
import inputFile from '@/components/inputFile';
import { audioPaly } from "@/utils";
import { tongue } from "@/utils/audio";

export default {
  name: 'confirmToface',
  data(){
      return{
          device:3
      }
  },
  components:{
    vueHeader,
    face,
    inputFile
  },
  created:function(){
      let device = sessionStorage.getItem('device');
      if(device=='android' || device=='windows'){
        this.device = 1;
      }else{
        this.device = 2;
      }
  },
  methods:{
      photoFun(){
        this.$router.push({
              name:'step2',
        });
        //语音
        audioPaly.play(tongue);
      }
  }
}
</script>
<style lang="scss" scoped rel="stylesheet/scss">
  @import 'confirmtongue';
</style>